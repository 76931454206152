import { isLogin, isLoginAdmin } from '@src/utils/base';
import { createRouter, createWebHistory } from 'vue-router';
import type { RouteRecordRaw } from 'vue-router';
import 'vue-router';

const routes: Array<RouteRecordRaw> = [
  { path: '/:pathMatch(.*)', component: () => import('@src/components/main.vue') },
  {
    // 入口页
    path: '/',
    name: 'Home',
    component: () => import('@src/components/main.vue'),
    // component: () => import('@src/components/test.vue'),
    meta: {
      title: 'NiuNiu',
      icon: '/Niu.webp',
      requiresAuth: false,
    },
  },
  {
    // 功能页 - GuiGuiGo 一键部署
    path: '/guiguigo',
    name: 'guiguigo',
    component: () => import('@src/components/domain/guiguigo.vue'),
    meta: {
      title: 'GuiGuiGo',
      icon: '/Niu.webp',
      requiresAuth: true,
      isAdmin: true,
      newWindow: true,
    },
  },
  {
    // 功能页 - 烟火神
    path: '/fireworks',
    name: 'fireworks',
    component: () => import('@src/components/domain/fireworks.vue'),
    meta: {
      title: '烟火神',
      icon: '/Niu.webp',
      requiresAuth: false,
      isAdmin: false,
      newWindow: true,
    },
  },
  {
    // 功能页 - 大屏基础演示
    path: '/dapingbase',
    name: 'dapingbase',
    component: () => import('@src/components/domain/DaPing/HomeView.vue'),
    children: [
      {
        path: '/dapingbase',
        components: {
          head: () => import('@src/components/domain/DaPing/heads/Head.vue'),
          content: () => import('@src/components/domain/DaPing/contents/Content.vue'),
          // content: () => import('@src/components/domain/DaPing/contents/Content-store.vue'),
        },
      },
    ],
    meta: {
      title: '全晰制造',
      icon: '/quanxi.ico',
      requiresAuth: false,
      isAdmin: false,
      newWindow: true,
    },
  },
  {
    // 功能页 - 英科医疗主页
    path: '/yingkeIndex',
    name: 'yingkeIndex',
    component: () => import('@src/components/domain/YingKeIndex/yingke-index.vue'),
    meta: {
      title: '英科医疗',
      icon: '/Niu.webp',
      requiresAuth: false,
      isAdmin: false,
      newWindow: true,
    },
  },
];

declare module 'vue-router' {
  interface RouteMeta {
    /** 浏览器页签标题 */
    title?: string;
    /** 浏览器页签图标 */
    icon?: string;
    /** 管理员权限 */
    isAdmin?: boolean;
    /** 登录权限 */
    requiresAuth: boolean;
    /** 是否新窗口打开 */
    newWindow?: boolean;
  }
}

export const router = createRouter({
  history: createWebHistory(), // 历史记录模式
  routes,
});

// 路由守卫
router.beforeEach(async (to) => {
  console.log('路由守卫：', to);

  // 判断是否需要登录
  if (to.meta.requiresAuth && to.fullPath !== '/') {
    if (to.meta.isAdmin) {
      // 判断是否是管理员
      if (!(await isLoginAdmin())) {
        window.$message.error('权限不足');
        return { name: 'Home' };
      }
      return true;
    } else if (isLogin()) {
      // 判断是否登录
      return true;
    } else {
      return { name: 'Home' };
    }
  }

  // 路由发生变化修改页面title
  if (to.meta.title) {
    document.title = `${to.meta.title}`;
  }
  // 路由发生变化修改页面图标
  if (to.meta.icon) {
    document.getElementsByTagName('link')[0].setAttribute('href', to.meta.icon);
  }
  return true;
});
