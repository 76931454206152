/**
 * 封装常用方法
 */
import { useCookies } from '@vueuse/integrations/useCookies';
import type { AxiosInstance } from 'axios';
import { inject } from 'vue';

/**
 * 判断是否登录
 */
export const isLogin = () => {
  const cookies = useCookies();
  return cookies.get('userEmail') && cookies.get('token') ? cookies.get('userEmail') : false;
};

/**
 * 判断是否是管理员
 * @returns boolean 是否是管理员
 */
export const isLoginAdmin = async () => {
  const axios = inject<AxiosInstance>('axios');
  try {
    const res = await axios.get('/auth/isAdmin');
    if (res.data) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

/**
 * 判断是否是移动端
 * @returns boolean 是否是移动端
 */
export const isMobile = () => {
  const ua = navigator.userAgent;
  return /Android|webOS|iPhone|iPod|BlackBerry/i.test(ua);
};

/**
 * vh转像素
 * @param type 类型
 * @param vh 高度
 * @returns 像素
 */
export const vh2px = (vNumber: number, type: 'vh' | 'vw' = 'vh') => {
  const h = document.documentElement.clientHeight;
  const w = document.documentElement.clientWidth;
  return type === 'vh' ? (vNumber * h) / 100 : (vNumber * w) / 100;
};

/**
 * 获取页面高度
 * @returns 页面高度
 */
export const getPageHeight = () => {
  return document.documentElement.scrollHeight;
};

/**
 * 存储 localStorage
 * @param key 键名
 * @param value 值
 */
export const setStorage = (key: string, value: any) => {
  localStorage.setItem(key, JSON.stringify(value));
};

/**
 * 获取 localStorage
 * @param key 键名
 * @returns 查询结果
 */
export const getStorage = (key: string) => {
  return JSON.parse(localStorage.getItem(key) ?? null);
};

/**
 * 刷新页面
 */
export const reload = () => {
  window.location.reload();
};

/**
 * 打开页面
 * @param url 页面url
 */
export const openUrl = (url: string) => {
  window.open(url);
};

/**
 * 组件视窗懒加载
 * 自定义全局指令
 * 需要组件进行配合  指令绑定元素 → 创建 Observer 监听 → 元素进入视窗 → 触发回调 → 修改响应式变量 → v-if 控制组件渲染
 * 
 * 关于 setTimeout 延时启动，如果首屏没有高度填满屏幕，所有组件就有可能同时加载，这时视窗监听变得十分不可控，最简单有效的方式就是设置延时等待首屏组件加载完毕，有了最起码一屏幕的高度后再进行视窗监听
 */
export const lazyShow = {
  mounted(el: any, binding: any) {
    setTimeout(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              // 执行回调函数或更新响应式变量
              if (typeof binding.value === 'function') {
                // 如果指令绑定的是一个 函数（如 v-lazy-show="loadComponent"），直接调用该函数
                binding.value();
              } else if (binding.value?.value !== undefined) {
                // 如果指令绑定的是一个 响应式对象（如 v-lazy-show="{ value: isVisible }"），更新其 value
                binding.value.value = true;
              }
              observer.unobserve(el); // 停止观察
            }
          });
        },
        {
          threshold: 0.1, // 可自定义阈值
        },
      );

      el._observer = observer; // 存储observer以便卸载
      observer.observe(el);
    }, 500);
  },
  unmounted(el: any) {
    if (el._observer) {
      el._observer.disconnect();
    }
  },
};
