/**
 * 封装 axios 的基础配置
 */

import axios from 'axios';
import { useCookies } from '@vueuse/integrations/useCookies';

const cookies = useCookies();

const axiosInstance = axios.create({
  baseURL: 'https://www.moyuwcc.cn/potato',
  timeout: 60000, // 超时时间 60s
});
 
// 请求拦截器
axiosInstance.interceptors.request.use(
  config => {
    // 在发送请求之前做些什么，例如设置token、将数据转为JSON格式等

    // 设置 token
    const token = cookies.get('token'); // 获取 token
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    
    return config;
  },
  error => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);
 
// 响应拦截器
axiosInstance.interceptors.response.use(
  (response) => {
    // 对响应数据做点什么
    if (response.data.code === 401) {
      // token 过期
      cookies.remove('token');
      return Promise.reject(response);
    }else if (response.status.toString().startsWith("2")){
      // 2 开头 登录成功
      return Promise.resolve(response)
    }else{
      return Promise.reject(response);
    }
  },
  (error) => {
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);
 
export default axiosInstance;