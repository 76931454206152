import { createApp } from 'vue';
import './style.css';
import App from './App.vue';
import axiosInstance from './utils/axios';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'; // Pinia 持久化插件
import { lazyShow } from './utils/base';

import VueLazyLoad from 'vue3-lazyload'; // 图片懒加载
import { router } from './router';

const pinia = createPinia(); // Pinia
pinia.use(piniaPluginPersistedstate); // Pinia 持久化插件

const app = createApp(App);

app.use(router); // vue-router
app.use(pinia); // Pinia

// 全局提供 axios 实例
// app.config.globalProperties.$axios = axiosInstance;

// 依赖注入提供 axios 实例。 组件中 const axios = inject<AxiosInstance>('axios') 获取实例
app.provide('axios', axiosInstance);

// 图片懒加载配置
app.use(VueLazyLoad, {
  loading: '/Niu.webp',
  error: '/Niu.webp',
});

// 全局指令 组件视窗懒加载
app.directive('lazy-show', lazyShow);

app.mount('#app');
